import { h } from 'preact';
import { lazy, Suspense } from 'preact/compat';

// webpackPreload: true is not working in webpack 4,
// so we are preloading manually in our template.html
const headerPromise = import(/* webpackChunkName: "header" */'./headerComponent');
const HeaderComponent = lazy(() => headerPromise);

const Header = ({
  floating,
  transparent,
  ...props
}) => (
  <div style={{ minHeight: !floating ? '57px' : null }}>
    <Suspense fallback={<div />}>
      <HeaderComponent {...props} floating={floating} transparent={transparent} />
    </Suspense>
  </div>
);

export default Header;
