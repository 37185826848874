import { h } from 'preact';
import { connect } from 'redux-zero/react';
import { getCurrentUrl } from 'preact-router';
import faGlobeStand from '@fortawesome/fontawesome-pro/svgs/light/globe-stand.svg';
import { open } from '../../languageModal/actions';
import { sendLog } from '../../../helpers/apis/logger';
import style from '../style.scss';
import FaIcon from '../../common/faIcon';
import Intl from '../../common/intl';

const LanguageButton = ({ availableLanguages, closeFn, openLanguageModal }) => {
  if (!availableLanguages || availableLanguages.length <= 1) {
    return null;
  }

  const langClickHandler = () => {
    sendLog('info', {}, getCurrentUrl(), 'languageModal', 'triggered');
    openLanguageModal();
    closeFn();
  };

  return (
    <button
      type="button"
      className={style.navbarItem}
      onClick={langClickHandler}
    >
      <span className={style.menuItemIcon}>
        <FaIcon icon={faGlobeStand} />
      </span>
      <span className={style.menuItemText}>
        <Intl textKey="I18N_MENU_LANGUAGE">Language</Intl>
      </span>
    </button>
  );
};

export default connect(
  ({ event: { availableLanguages } }) => ({
    availableLanguages,
  }),
  () => ({ openLanguageModal: open }),
)(LanguageButton);
